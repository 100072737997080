.title{
    font-size: 3rem;
    font-family: "Assistant";
    text-align: center;
    margin:2% auto;
    text-shadow:  2px 1px 4px #00000085;
    direction: rtl;
}
.description{
    font-size: 2rem;
    font-family: "Assistant";
    text-align: center;
    margin:2% auto;
    direction: rtl;
}
.but{
    font-size: 1.4rem;
    font-family: "Assistant";
    text-align: center;
    margin:2% auto;
    direction: rtl;
}
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin:3% auto;
    flex-wrap: wrap;
}
@media only screen and (max-width: 350px) {
    .title{
        font-size: 2rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        text-shadow:  2px 1px 4px #00000085;
        direction: rtl;
    }
    .description{
        font-size: 1.7rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .but{
        font-size: 1rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin:3% auto;
    }
}
@media only screen and (min-width: 350px) and (max-width: 450px) {
    .title{
        font-size: 2rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        width:90%;
        text-shadow:  2px 1px 4px #00000085;
        direction: rtl;
    }
    .description{
        font-size: 1.8rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .but{
        font-size: 1.1rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin:3% auto;
    }
}
@media only screen and (min-width: 450px) and (max-width: 550px) {
    .title{
        font-size: 2.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        width:90%;
        text-shadow:  2px 1px 4px #00000085;
        direction: rtl;
    }
    .description{
        font-size: 2rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .but{
        font-size: 1.2rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin:3% auto;
    }
}
@media only screen and (min-width: 550px) and (max-width: 650px) {
    .title{
        font-size: 2.8rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        width:90%;
        text-shadow:  2px 1px 4px #00000085;
        direction: rtl;
    }
    .description{
        font-size: 2.2rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .but{
        font-size: 1.4rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin:3% auto;
    }
}