.button {
    padding: 1rem 2rem;
    border-radius: .5rem;
    border: none;
    font-size: 1rem;
    width:200px;
    margin:2% auto;
    justify-content: center;
    font-weight: 400;
    color: #f4f0ff;
    text-align: center;
    backdrop-filter: blur(10px);
    cursor: pointer;
    direction: rtl;
    position: relative;
    font-family: "Assistant";
  }
  
  .button::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: .5rem;
    background: linear-gradient(180deg, rgba(8, 77, 126, 0) 0%, rgba(8, 77, 126, 0.42) 100%),rgba(47,255,255,.24);
    box-shadow: inset 0 0 12px rgba(151,200,255,.44);
    z-index: -1;
  }
  
  .button::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(8, 77, 126, 0) 0%, rgba(8, 77, 126, 0.42) 100%),rgba(47,255,255,.24);
    box-shadow: inset 0 0 12px rgba(151,200,255,.44);
    border-radius: .5rem;
    opacity: 0;
    z-index: -1;
    transition: all .3s ease-in;
  }
  
  .button:hover::after {
    opacity: 1;
  }
  
