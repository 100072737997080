.center{
    width:20%;
    display: flex;
    justify-content: center;
    margin:2% auto;
}
.title{
    font-size: 3rem;
    font-family: "Assistant";
    text-align: center;
    margin:1% auto;
    text-shadow:  2px 1px 4px #00000085;
    direction: rtl;
}
.description{
    font-size: 1.4rem;
    font-family: "AssistantR";
    text-align: center;
    line-height: 1.5;
    margin:1% auto;
    width:90%;
    text-shadow:  2px 1px 4px #00000085;
    direction: rtl;
}
.image{
    width: 100%;
    object-fit: contain;
cursor: pointer;
}

@media only screen and (max-width: 350px) {
    .center{
        width:60%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .title{
        font-size: 2.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .description{
        font-size: 1.2rem;
        font-family: "AssistantR";
        text-align: center;
        line-height: 1.5;
        margin:1% auto;
        width:90%;
        text-shadow:  2px 1px 4px #00000085;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
}
@media only screen  and (min-width: 350px) and (max-width: 450px) {
    .center{
        width:50%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .title{
        font-size: 2.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .description{
        font-size: 1.25rem;
        font-family: "AssistantR";
        text-align: center;
        line-height: 1.5;
        margin:1% auto;
        width:90%;
        text-shadow:  2px 1px 4px #00000085;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
}
@media only screen  and (min-width: 450px) and (max-width: 550px) {
    .center{
        width:45%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .title{
        font-size: 2.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
}
@media only screen  and (min-width: 550px) and (max-width: 650px) {
    .center{
        width:40%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .title{
        font-size: 2.8rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .description{
        font-size: 1.25rem;
        font-family: "AssistantR";
        text-align: center;
        line-height: 1.5;
        margin:1% auto;
        width:90%;
        text-shadow:  2px 1px 4px #00000085;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
}
@media only screen  and (min-width: 650px) and (max-width: 750px) {
    .center{
        width:35%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .title{
        font-size: 3rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
}
@media only screen  and (min-width: 750px) and (max-width: 950px) {
    .center{
        width:30%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .title{
        font-size: 3rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
}
@media only screen  and (min-width: 950px) and (max-width: 1150px) {
    .center{
        width:25%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .title{
        font-size: 3rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
}