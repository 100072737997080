.name{
    font-size: 3.5rem;
    font-family: "Assistant";
    text-align: center;
    margin:0 auto;
    padding-top:10px;
    direction: rtl;
    text-shadow:  2px 1px 4px #00000085;
}
.nameP{
    font-size: 3.5rem;
    font-family: "Assistant";
    text-align: center;
    margin:0 auto;
    padding-top:90px;
    direction: rtl;
}
.description{
    font-size: 1.8rem;
    font-family: "Assistant";
    text-align: center;
    margin:1% auto;
    direction: rtl;
}
.who{
    font-size: 2rem;
    font-family: "Assistant";
    text-align: center;
    margin:1% auto;
    direction: rtl;
}
.center{
    width:15%;
    display: flex;
    justify-content: center;
    margin:2% auto;
}
.image{
    width: 100%;
    object-fit: contain;
}
.column{
    width:30%;
    display: flex;
    justify-content: center;
    flex-direction: column;
margin:0 auto;
}
.background{
    /* background: linear-gradient(135deg, rgba(245,245,220,1) 0%, rgba(251,232,212,1) 100%); */
}

@media only screen and (max-width: 350px) {

    .name{
        font-size: 2.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:0 auto;
        padding-top:10px;
        direction: rtl;
        text-shadow:  2px 1px 4px #00000085;
    }
    .nameP{
        font-size: 2.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:0 auto;
        padding-top:90px;
        direction: rtl;
    }
    .description{
        font-size: 1.2rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .who{
        font-size: 1.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .center{
        width:65%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
    .column{
        width:90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    margin:0 auto;
    }
}
@media only screen and (min-width: 350px) and (max-width:450px) {

    .name{
        font-size: 2.8rem;
        font-family: "Assistant";
        text-align: center;
        margin:0 auto;
        padding-top:10px;
        direction: rtl;
        text-shadow:  2px 1px 4px #00000085;
    }
    .nameP{
        font-size: 2.8rem;
        font-family: "Assistant";
        text-align: center;
        margin:0 auto;
        padding-top:90px;
        direction: rtl;
    }
    .description{
        font-size: 1.4rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .who{
        font-size: 1.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .center{
        width:60%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
    .column{
        width:90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    margin:0 auto;
    }
}
@media only screen and (min-width: 450px) and (max-width:550px) {

    .name{
        font-size: 3rem;
        font-family: "Assistant";
        text-align: center;
        margin:0 auto;
        padding-top:10px;
        direction: rtl;
        text-shadow:  2px 1px 4px #00000085;
    }
    .nameP{
        font-size: 3rem;
        font-family: "Assistant";
        text-align: center;
        margin:0 auto;
        padding-top:90px;
        direction: rtl;
    }
    .description{
        font-size: 1.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .who{
        font-size: 1.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .center{
        width:50%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
    .column{
        width:90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    margin:0 auto;
    }
}

@media only screen and (min-width: 550px) and (max-width:650px) {

    .name{
        font-size: 3.2rem;
        font-family: "Assistant";
        text-align: center;
        margin:0 auto;
        padding-top:10px;
        direction: rtl;
        text-shadow:  2px 1px 4px #00000085;
    }
    .nameP{
        font-size: 3.2rem;
        font-family: "Assistant";
        text-align: center;
        margin:0 auto;
        padding-top:90px;
        direction: rtl;
    }
    .description{
        font-size: 1.6rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .who{
        font-size: 1.6rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .center{
        width:45%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
    .column{
        width:90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    margin:0 auto;
    }
}
@media only screen and (min-width: 650px) and (max-width:850px) {

    .name{
        font-size: 3.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:0 auto;
        padding-top:10px;
        direction: rtl;
        text-shadow:  2px 1px 4px #00000085;
    }
    .nameP{
        font-size: 3.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:0 auto;
        padding-top:90px;
        direction: rtl;
    }
    .description{
        font-size: 1.8rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .who{
        font-size: 1.8rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .center{
        width:35%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    .image{
        width: 100%;
        object-fit: contain;
    }
    .column{
        width:90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    margin:0 auto;
    }
}