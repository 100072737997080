.box {
    position: relative;
    width: 400px;
    height: 250px;
    margin: 20px auto;
    border-radius: 10px 0 10px 0;
    box-shadow: 0 0 5px 1px #444;
    overflow: hidden;

    direction: rtl;
  }
  
  .glassEffect {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(140, 138, 138, 0.2);
    backdrop-filter: blur(70px); /* אפקט הזכוכית */
  }
  
  .content {
    position: relative;
    z-index: 1; /* כדי להביא את התוכן מעל האפקט */
    padding: 20px;

  }
  
  .number {
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-family: "Assistant";
  }
  
  .title {
    font-size: 1.4rem;
    margin-bottom: 30px;
    margin-top:10px;
    font-family: "Assistant";
    text-shadow:  1px 1px 4px #7f7f7f;
  }
  
  .description {
    font-size: 1.1rem;
    font-family: "AssistantR";
  }
  .center{
position: relative;
display: flex;
direction: rtl;
justify-content: end;

padding:10px;

  }
  .button{
    display: flex;
    width:60%;
  cursor: pointer;
    z-index: 2; 
    text-align: center;
    justify-content: center; /* שינוי למרכז */
    font-family: "AssistantR";
    font-size: 1rem;
    padding:0.4rem 0.2rem;
    box-shadow: 0 0 6px 1px #777 ;
    border:none;
    background: none;
    border-radius: 5px;
    transition: all ease 1.1s;
  }
  .button:hover{
  transform: scale(1.05);
  }
  @media only screen and (max-width: 350px) {

    .box {
        position: relative;
        width: 300px;
        height: 220px;
        margin: 20px auto;
        border-radius: 10px 0 10px 0;
        box-shadow: 0 0 5px 1px #444;
        overflow: hidden;
    
        direction: rtl;
      }
      
      .glassEffect {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(140, 138, 138, 0.2);
        backdrop-filter: blur(70px); /* אפקט הזכוכית */
      }
      
      .content {
        position: relative;
        z-index: 1; /* כדי להביא את התוכן מעל האפקט */
        padding: 20px;
    
      }
      
      .number {
        font-size: 1.5rem;
        margin-bottom: 10px;
        font-family: "Assistant";
      }
      
      .title {
        font-size: 1.25rem;
        margin-bottom: 10px;
        margin-top:10px;
        font-family: "Assistant";
        text-shadow:  1px 1px 4px #7f7f7f;
      }
      
      .description {
        font-size: 1rem;
        font-family: "AssistantR";
      }
      .center{
    position: relative;
    display: flex;
    direction: rtl;
    justify-content: end;
    
    padding:10px;
    
      }
      .button{
        display: flex;
        width:60%;
      cursor: pointer;
        z-index: 2; 
        text-align: center;
        justify-content: center; /* שינוי למרכז */
        font-family: "AssistantR";
        font-size: 1rem;
        padding:0.4rem 0.2rem;
        box-shadow: 0 0 6px 1px #777 ;
        border:none;
        background: none;
        border-radius: 5px;
        transition: all ease 1.1s;
      }
      .button:hover{
      transform: scale(1.05);
      }

  }

  @media only screen  and (min-width: 350px) and (max-width: 450px) {

    .box {
        position: relative;
        width: 320px;
        height: 240px;
        margin: 20px auto;
        border-radius: 10px 0 10px 0;
        box-shadow: 0 0 5px 1px #444;
        overflow: hidden;
    
        direction: rtl;
      }
      
      .glassEffect {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(140, 138, 138, 0.2);
        backdrop-filter: blur(70px); /* אפקט הזכוכית */
      }
      
      .content {
        position: relative;
        z-index: 1; /* כדי להביא את התוכן מעל האפקט */
        padding: 20px;
    
      }
      
      .number {
        font-size: 1.5rem;
        margin-bottom: 10px;
        font-family: "Assistant";
      }
      
      .title {
        font-size: 1.4rem;
        margin-bottom: 10px;
        margin-top:10px;
        font-family: "Assistant";
        text-shadow:  1px 1px 4px #7f7f7f;
      }
      
      .description {
        font-size: 1.1rem;
        font-family: "AssistantR";
      }
      .center{
    position: relative;
    display: flex;
    direction: rtl;
    justify-content: end;
    
    padding:10px;
    
      }
      .button{
        display: flex;
        width:60%;
      cursor: pointer;
        z-index: 2; 
        text-align: center;
        justify-content: center; /* שינוי למרכז */
        font-family: "AssistantR";
        font-size: 1rem;
        padding:0.4rem 0.2rem;
        box-shadow: 0 0 6px 1px #777 ;
        border:none;
        background: none;
        border-radius: 5px;
        transition: all ease 1.1s;
      }
      .button:hover{
      transform: scale(1.05);
      }

  }
  
  @media only screen  and (min-width:800px) and (max-width: 950px) {

    .box {
        position: relative;
        width: 350px;
        height: 250px;
        margin: 20px auto;
        border-radius: 10px 0 10px 0;
        box-shadow: 0 0 5px 1px #444;
        overflow: hidden;
    
        direction: rtl;
      }
      
      .glassEffect {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(140, 138, 138, 0.2);
        backdrop-filter: blur(70px); /* אפקט הזכוכית */
      }
      
      .content {
        position: relative;
        z-index: 1; /* כדי להביא את התוכן מעל האפקט */
        padding: 20px;
    
      }
      
      .number {
        font-size: 1.5rem;
        margin-bottom: 10px;
        font-family: "Assistant";
      }
      
      .title {
        font-size: 1.4rem;
        margin-bottom: 10px;
        margin-top:10px;
        font-family: "Assistant";
        text-shadow:  1px 1px 4px #7f7f7f;
      }
      
      .description {
        font-size: 1.1rem;
        font-family: "AssistantR";
      }
      .center{
    position: relative;
    display: flex;
    direction: rtl;
    justify-content: end;
    
    padding:10px;
    
      }
      .button{
        display: flex;
        width:60%;
      cursor: pointer;
        z-index: 2; 
        text-align: center;
        justify-content: center; /* שינוי למרכז */
        font-family: "AssistantR";
        font-size: 1rem;
        padding:0.4rem 0.2rem;
        box-shadow: 0 0 6px 1px #777 ;
        border:none;
        background: none;
        border-radius: 5px;
        transition: all ease 1.1s;
      }
      .button:hover{
      transform: scale(1.05);
      }

  }
